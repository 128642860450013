var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M12 8C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 8C4 5.79086 5.79086 4 8 4C10.2091 4 12 5.79086 12 8ZM10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M17 8H19V11H22V13H19V16H17V13H14V11H17V8Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12 19H14C14 15.6863 11.3137 13 8 13C4.68629 13 2 15.6863 2 19H4C4 16.7909 5.79086 15 8 15C10.2091 15 12 16.7909 12 19Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }